import React from 'react';
import { Grid } from '@mui/material';
import { SingleColumnWithImageMenu } from './NavPanelComponents/NavPanelGridComponents/SingleColumnWithImageMenu';
import { TwoColumnMenu } from './NavPanelComponents/NavPanelGridComponents/TwoColumnMenu';
import { MenuLinks } from './NavPanelComponents/NavPanelGridComponents/MenuLinks';
import { NavbarColumn } from '@models/navigationBar';

interface DropDownMenuProps {
    closeMenu?: () => void;
    columnData: NavbarColumn;
    previewState?: boolean;
    fullWidth?: boolean;
}

export function DropDownMenu({ closeMenu, columnData, previewState, fullWidth }: DropDownMenuProps): JSX.Element {
    const renderSingleColumn = (columnData: NavbarColumn): JSX.Element | undefined => {
        switch (columnData.content?.type) {
            case 'withImage':
                return (
                    <SingleColumnWithImageMenu
                        content={columnData.content}
                        closeMenu={closeMenu}
                        previewState={previewState}
                    />
                );
            case 'withDescriptionLinks':
                return (
                    <MenuLinks
                        title={columnData.content?.title}
                        menuItems={columnData.content?.links}
                        type={columnData.content?.type}
                        closeMenu={closeMenu}
                        previewState={previewState}
                    />
                );
            default:
                break;
        }
    };

    return (
        <Grid item xs={fullWidth ? 12 : 6}>
            {columnData.type === 'singleColumn' ? (
                renderSingleColumn(columnData)
            ) : columnData.type === 'twoColumn' ? (
                <TwoColumnMenu
                    columnsArray={[columnData.leftContent, columnData.rightContent]}
                    hasDivider={columnData.hasDivider}
                    columnsPropsArray={[{}, { spacing: false }]}
                    closeMenu={closeMenu}
                    previewState={previewState}
                />
            ) : null}
        </Grid>
    );
}
